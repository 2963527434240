import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AppProvider } from "./Context/AppState";

import {
  ToastProvider,
  DefaultToastContainer,
} from 'react-toast-notifications';

// Component imports
import Footer from "./iissn/Footer";
import NavigationBar from "./iissn/Nav";
import Home from "./iissn/Home";
import Register from './iissn/Register';

// Routing
// import AuthRoute from './Components/Common/AuthRoute';
// import PrivateRoute from './Components/Common/PrivateRoute';

import "./App.css";

function App() {

  //adds the context to window so we can access anywhere
  window.__react_toast_provider = React.createRef();

  //create a default container so we can override the styles
  const ToastContainer = (props) => (
    <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
  );

  return (
    <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider} >
    <AppProvider>
      <BrowserRouter>
        <NavigationBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path='/register' component={Register} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </AppProvider>
    </ToastProvider>
  );
}

export default App;
