import React, { useState, createContext, useEffect } from "react";

// initial state
const initialState = {
  token: "",
  userId: '',
  userEmail: '',
  userName: '',
  isAuthenticated: false,
  loginError: false,
  loginErrorMessage: "",
  registerError: false,
  registerErrorMessage: "",
};

// create context with initialState
export const AppContext = createContext(initialState);

//function to load state from session storage
const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

//get state from session storage
const storedState = loadState();

//provider function
export function AppProvider(props) {
  const [appState, setAppState] = useState(storedState || initialState);

  //function to change values of app state
  const changeState = (state, value) => {
    setAppState({
      ...appState,
      [state]: value,
    });
  };

  //useEffect to save state to session anytime it changes
  useEffect(() => {
    sessionStorage.setItem("state", JSON.stringify(appState));
  }, [appState]);

  return (
    <AppContext.Provider value={{ appState, changeState }}>
      {props.children}
    </AppContext.Provider>
  );
}
