import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Navigation = () => {
  return (
    <>
      <Navbar expand="lg">
  <Container>
    <Navbar.Brand>
      <h3 className='text-success'>IISSN</h3>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
      <Navbar.Text>
      <Link className='mr-5' to="/">Seminar</Link>
      </Navbar.Text>
      <Navbar.Text>
      <Link className='mr-5' to="/">Conference</Link>
      </Navbar.Text>
      <Navbar.Text>
      <Link className='mr-5' to='/'>Courses</Link>
      </Navbar.Text>
    </Navbar.Collapse>
  </Container>
</Navbar>
    </>
  )
}

export default Navigation;
