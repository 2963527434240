import React from 'react';
import { Link } from 'react-router-dom'
const camera = require('./camera.jpg');

const Home = () => {
  return (
    <div>
      <div className="container">
        <div className="box">
        <img src={camera} className='d-block w-100' alt='Security Camera'/>
        <div className="centered">
          <h3 className="text-success">
            Seminar on Industrial Security In October
          </h3>
          <Link to='/register' className='btn btn-outline-success' style={{textDecoration:'none'}}>
            Register
          </Link>
        </div>
        </div>
        
        <div className="history mt-5">
          <h4 className='text-success'> History </h4>
          <p className="lead mt-5">
          Founded over a decade ago, the Institute of Industrial Security and Safety of Nigeria (IISSN) was formally inaugurated during a National Conference for Industrial Security and Safety Managers on the 28th December, 1995 at the Lagos Sheraton Hotel and Towers, Ikeja, Lagos, to fill the vacuum created by the absence of a full-fledged professional body representing the national interest of the security and safety practitioners in Nigeria.
          </p>
          <p className="lead mt-3">
          The Institute was registered in July, 1999 as a Public Trust Institute with Incorporated Board of Trustees. By virtue of its incorporation the Institute is an independent Public Trust and a self- regulatory professional organization opened to all who want to make industrial security and Health, Safety and Environment (HSE) a profession.
          </p>
        </div>
        <div className="partners mt-5"></div>
      </div>
    </div>
  )
}

export default Home;