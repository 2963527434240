import React from 'react'

const Register = () => {
  return (
    <div>
      <div className="container">
        <div className="text-center">
        <iframe title='Registration Form' src="https://docs.google.com/forms/d/e/1FAIpQLSegEUdqZD9t9HH-qjO_JXP4bZEHov-1jRB_zX8HvnGwZdUvmA/viewform?embedded=true" className='w-100' height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </div>
    </div>
  )
}

export default Register;
