import React from 'react'
// import { Link } from 'react-router-dom'
const logo = require('./logo.png');

const Footer = () => {
  return (
    <div classNAme='bg-dark'>
    <div className='container'>
    <div className="row mt-5">
      <div className="col-12">
        <div className="text-center">
        <img src={logo} alt="IISSN SECURITY" />
        </div>
      </div>
      {/* <div className="col-2">
        <p className="lead">
        <Link to='/' style={{textDecoration:'none', color:'black'}} className="lead">Seminar</Link>
        </p>
      </div> */}
      {/* <div className="col-2">
      <p className="lead">
        <Link to='/' style={{textDecoration:'none', color:'black'}} className="lead">Conference</Link>
        </p>
      </div> */}
      {/* <div className="col-2">
      <p className="lead">
        <Link to='/' style={{textDecoration:'none', color:'black'}} className="lead">Courses</Link>
        </p>
      </div> */}
    </div>
      <div className="mt-5">
      <h4 className='text-center'>Powered By BSIIM Platform</h4>
      </div>
    </div>
    </div>
  )
}

export default Footer;
